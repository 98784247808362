"use client"

import { Button } from "@nextui-org/button"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown"
import { Link } from "@nextui-org/link"
import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarBrand,
  NavbarItem,
} from "@nextui-org/navbar"
import NextLink from "next/link"
import { ReactNode, useState } from "react"

import HamburgerMenu from "@/components/HamburgerMenu"
import {
  ArrowDownsLineIcon,
  DiscordIcon,
  Logo,
  TwitterIcon,
} from "@/components/icons"
import { ThemeSwitch } from "@/components/theme-switch"
import { siteConfig } from "@/config/site"

export const Navbar = ({ children }: { children?: ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <NextUINavbar
      maxWidth="xl"
      position="sticky"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      motionProps={{
        initial: { opacity: 0, scale: 0.98 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.98 },
        transition: { duration: 0.025 },
      }}
    >
      <NavbarContent justify="start">
        <div className="md:hidden">
          <HamburgerMenu
            menuItems={siteConfig.navItems}
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
          />
        </div>
        <NavbarBrand as="li" className="gap-3">
          <NextLink className="flex items-center justify-start" href="/">
            <Logo />
          </NextLink>
        </NavbarBrand>
        <ul className="hidden items-center justify-start gap-10 md:flex">
          {siteConfig.navItems.map((item) => (
            <NavbarContent key={item.title}>
              {item.items.length > 1 ? (
                <Dropdown>
                  <NavbarItem>
                    <DropdownTrigger>
                      <Button
                        className="bg-transparent p-0 font-bold data-[hover=true]:bg-transparent"
                        variant="light"
                        size="lg"
                        endContent={<ArrowDownsLineIcon className="h-6 w-6" />}
                      >
                        {item.title}
                      </Button>
                    </DropdownTrigger>
                  </NavbarItem>
                  <DropdownMenu
                    aria-label={item.title}
                    className="w-[280px]"
                    itemClasses={{
                      base: "gap-4",
                    }}
                  >
                    {item.items.map((subItem) => (
                      <DropdownItem key={subItem.label} href={subItem.href}>
                        <p>{subItem.label}</p>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <NavbarItem>
                  <NextLink href={item.items[0].href} passHref>
                    <Button
                      className="text-md bg-transparent p-0 font-bold data-[hover=true]:bg-transparent"
                      radius="lg"
                      variant="light"
                    >
                      {item.title}
                    </Button>
                  </NextLink>
                </NavbarItem>
              )}
            </NavbarContent>
          ))}
        </ul>
      </NavbarContent>
      <NavbarContent justify="end">
        <div>{children}</div>
        <NavbarItem className="flex gap-1 sm:gap-5">
          <Link isExternal href={siteConfig.links.x} aria-label="Twitter">
            <TwitterIcon className="text-default-500" size={20} />
          </Link>
          <Link isExternal href={siteConfig.links.discord} aria-label="Discord">
            <DiscordIcon className="text-default-500" />
          </Link>
          <ThemeSwitch />
        </NavbarItem>
      </NavbarContent>
    </NextUINavbar>
  )
}
